import * as React from "react"

import Layout from "../Layout/layout"
import Seo from "../seo"

const settingWorkflow = () => {
  return (
    <Layout>
      <Seo title="Setting Workflow" />
      <div>Setting Workflow</div>
    </Layout>
  )
}

export default settingWorkflow

import * as React from "react"

import SettingWorkflow from "../../components/SettingWorkflow/settingWorkflow"
import Seo from "../../components/seo"
import PrivateRoute from "../../components/privateRoute"
import ReduxWrapper from "../../redux/reduxWrapper"

const IndexPage = props => {
  return (
    <React.Fragment>
      <Seo title="Setting Workflow" />
      <PrivateRoute component={SettingWorkflow} location={props.location} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<IndexPage {...props} />} />
export default WrappedPage
